@import "variables.scss";

.MuiDialog-container {
  .MuiDialog-paper {
    @import "./forms.scss";
    @import "./buttons.scss";
    border-radius: 24px;
    max-width: 713px;
    margin: 20px;
    max-height: auto;
    height: auto;
    .dialog_header {
      border-bottom: 1px solid #ddd;
      padding: 21px 24px;
    }
    .dialog_footer {
      border-top: 1px solid #ddd;
      padding: 12px 34px;
    }
  }
}

// For Class Modal
.addClassModal .MuiDialog-container .MuiDialog-paper {
  width: 800px;
  max-width: 800px;

  @media #{$mq-laptop} {
    width: 800px;
  }
  @media #{$mq-tablet-md} {
    width: auto;
  }
  @media #{$mq-mobile-sm} {
    width: auto;
  }
}
// For Class Modal

// For Add Note Modal
.AddNoteModal .MuiDialog-container .MuiDialog-paper {
  width: 712px;
  max-width: 800px;

  @media #{$mq-laptop} {
    width: 600px;
  }
  @media #{$mq-tablet-md} {
    width: auto;
  }
  @media #{$mq-mobile-sm} {
    width: auto;
  }
}
// For Add Note Modal
