@import "variables";

.label_numbering {
  background: var(--ty-secondary-color);
  @extend %ty_btn;
  @extend %ty_circle;
  height: 20px;
  width: 20px;
  display: inline-flex;
  line-height: 16.8;
  margin-right: 8px;
}