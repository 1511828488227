@import "variables";

// Custom Table Header
.ty_header_box {
  background: #f1f7f9;
  border-radius: 20px;
  padding: 16px;
}
.ty_header_box.markRaceModal {
  @media #{$mq-mobile-sm} {
    padding: 10px;
  }
}

//Table
.ty_table_container {
  background: #f1f7f9;
  padding: 16px 24px;
  border-radius: 16px;
  box-shadow: none;
  @media #{$mq-tablet-md} {
    max-height: 85%;
  }
  @media #{$mq-mobile-sm} {
    max-height: 76%;
  }
  th {
    border: 0;
  }
  .ty_datatable_row {
    td {
      border: 0;
      padding: 10px 8px;
      min-height: 60px;
      height: 60px;
      &:nth-child(01) {
        padding-left: 24px;
      }
      &:last-child {
        padding-right: 24px;
      }
      .MuiButtonBase-root {
        min-width: auto;
      }
      .MuiTypography-p {
        a {
          color: var(--ty-secondary-color);
          text-decoration: none;
          &:after {
            content: "";
            width: 0;
          }
        }
      }
    }
    background-color: var(--ty-white-color);
    &:nth-child(4n + 3) {
      background: #d8e9ee;
    }
    &:hover {
      background: #fdf6e2;
    }
  }
  .MuiTableRow-root td:first-child {
    border-top-left-radius: 48px;
    border-bottom-left-radius: 48px;
    // min-width: 150px;
  }
  .MuiTableRow-root td:last-child {
    border-top-right-radius: 48px;
    border-bottom-right-radius: 48px;
  }
}

//added css for score definition
.ty_table_container_exp {
  background: #f1f7f9;
  padding: 16px 24px 30px;
  border-radius: 16px;
  box-shadow: none;
  height: calc(100% - 120px);
  th {
    border: 0;
  }
  .rc-scrollbars-container {
    height: calc(100% - 75px) !important;
  }
  .ty_datatable_row {
    td {
      border: 0;
      .MuiButtonBase-root {
        min-width: auto;
      }
      .MuiTypography-p {
        a {
          color: var(--ty-secondary-color);
          text-decoration: none;
          &:after {
            content: "";
            width: 0;
          }
        }
      }
    }
    background-color: var(--ty-white-color);
    &:nth-child(4n + 3) {
      background: #d8e9ee;
    }
    &:hover {
      background: #fdf6e2;
    }
  }
  .MuiTableRow-root td:first-child {
    border-top-left-radius: 48px;
    border-bottom-left-radius: 48px;
  }
  .MuiTableRow-root td:last-child {
    border-top-right-radius: 48px;
    border-bottom-right-radius: 48px;
  }

  // For sticky 1st column and data
  .stickyTableCellWidth {
    min-width: 200px;
    @media #{$mq-tablet-sm} {
      min-width: 150px;
    }
    @media #{$mq-mobile-sm} {
      min-width: 150px;
    }
  }

  td.stickyTableCellWidth {
    position: sticky;
    left: -5px;
    background: inherit;
    z-index: 1;
    box-shadow: 1px 0px 0px 0px rgb(201 201 201 / 75%);
  }

  .stickyTableCellWidth {
    position: sticky;
    left: -5px;
  }

  .stickyTableCellWidth th {
    z-index: 2;
  }
  // For sticky 1st column and data
}

// For Result Option Allocation Header and Body Alignment
.ResultOptionAllocationTable {
  thead {
    tr {
      th {
        &:nth-child(1) {
          text-align: left;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        text-align: center;
        &:nth-child(1) {
          text-align: left;
        }
      }
    }
  }
}

// For Result Option Allocation Header and Body Alignment

// For Result Option Allocation Series Setup OTB Header and Body Alignment

.ResultOptionAllocationTableOTB {
  thead {
    tr {
      th {
        &:nth-child(1),
        &:nth-child(2) {
          text-align: left;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        text-align: center;
        &:nth-child(1) {
          text-align: left;
        }
      }
    }
  }
}

// For Result Option Allocation Series Setup OTB Header and Body Alignment

// For Result Option OTB  Header and Body Alignment

.extraOptionTable {
  thead {
    tr {
      th {
        text-align: center;
        width: 100px;
        &:nth-child(1) {
          text-align: left;
          min-width: 250px;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        text-align: center;
        &:nth-child(1) {
          text-align: left;
        }
      }
    }
  }
}

// For Result Option OTB Header and Body Alignment

.ty_table_container_scoringOptions {
  @media #{$mq-tablet-md} {
    max-height: 85%;
  }
  @media #{$mq-mobile-sm} {
    max-height: 40%;
  }
}
