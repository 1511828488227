// Custom Root Variables
@use "sass:math";
:root {
  --custom_padding_40px: 40px;
  --ty-white-color: #fff;
  --ty-primary-color: #356dad;
  --ty-primary-hovercolor: #2b598d;
  --ty-secondary-color: #42566c;
  --ty-teritory-color: rgba(128, 151, 178, 1);
  --ty-red-alert: #ff0505;
  --ty-font-family: "Hellix", sans-serif;

  //Race Days label Variable
  --wednesday_wonders: #03a3f2;
  --OD_short_series: #05a929;
  --published: #05a929;
  --saturday_racing: #ffbb00;
  --cruiser_series: #763da2;
  --missing_data: #ff0200;
  --new_data: #de7800;

  //Fonts Size Variables
  --font_size14: 14px;
  --font_size16: 16px;
  --font_size18: 18px;
  --font_size24: 24px;
}

/* Media Query */
$mq-4k: "(min-width: 2201px) and (max-width: 4000px)";
$mq-laptop: "(max-width: 1440.98px)";
$mq-tablet-lg: "(max-width: 1199.98px)";
$mq-tablet-md: "(max-width: 900.98px)";
$mq-tablet-sm: "(max-width: 767.98px)";
$mq-mobile-sm: "(max-width: 575.98px)";
$mq-mobile-xs: "(max-width: 479.98px)";

$baseWidth: 1920px;
$scalingFactor: 1.25;
$targetWidth: math.div($baseWidth, $scalingFactor);
$mediaQuery125: "only screen and (max-width: #{$targetWidth})";

// Component Fonts
%tyFont {
  font-size: var(--font_size16);
  @media #{$mediaQuery125} {
    font-size: calc(var(--font_size16) - 2px);
  }
  @media #{$mq-mobile-sm} {
    font-size: var(--font_size14);
  }
  font-style: normal;
  line-height: normal;
}

%ty_p_Font {
  font-size: var(--font_size18);
  @media #{$mediaQuery125} {
    font-size: calc(var(--font_size18) - 3px);
  }
  @media #{$mq-mobile-sm} {
    font-size: calc(var(--font_size18) - 2px);
  }
  font-style: normal;
  line-height: normal;
}

// %ty_p_Font {
//   font-size: var(--font_size16);
//   @media #{$mediaQuery125} {
//     font-size: calc(var(--font_size16) - 2px);
//   }
//   @media #{$mq-mobile-sm} {
//     font-size: var(--font_size14);
//   }
//   font-style: normal;
//   line-height: normal;
// }

%ty_innerheading_Font {
  font-size: var(--font_size24);
  @media #{$mediaQuery125} {
    font-size: calc(var(--font_size24) - 4px);
  }
  font-style: normal;
  line-height: normal;
}

// %ty_p_Font2 {
//   color: var(--ty-primary-color);
//   font-size: var(--font_size16);
//   @media #{$mediaQuery125} {
//     font-size: calc(var(--font_size16) - 2px);
//   }
//   @media #{$mq-mobile-sm} {
//     font-size: var(--font_size14);
//   }
//   font-style: normal;
//   line-height: normal;
// }

%ty_btn {
  font-size: var(--font_size14);
  @media #{$mediaQuery125} {
    font-size: calc(var(--font_size14) - 1.5px);
  }
  @media #{$mq-laptop} {
    font-size: calc(var(--font_size14) - 2px);
  }
  @media #{$mq-mobile-sm} {
    font-size: calc(var(--font_size14) - 2px);
  }
  @media #{$mq-mobile-xs} {
    font-size: calc(var(--font_size14) - 3px);
  }
  font-style: normal;
  line-height: normal;
}

%ty_heading {
  color: var(--ty-secondary-color);
  font-weight: 600;
  margin: 0;
}

%ty_input_design {
  &:hover {
    border: 1px solid #9dacbd;
  }

  .Mui-focused {
    border: 1px solid var(--ty-red-alert);
  }
}

%ty_circle {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--ty-white-color);
}

%line_cst {
  display: block;
  content: "";
  width: 100%;
  height: 1px;
  background-color: var(--ty-primary-color);
}
