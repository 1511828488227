@import "variables";

// Side Navigation
.ty_application_design {
  .custom-drawer-class {
    // ul li {
    //   .ty-Sidebar_menu_parent div {
    //     &:first-child {
    //       background: rgba(255, 255, 255, 0.1);
    //         padding: 8px;
    //         border-radius: 10px;
    //         display: flex;
    //         justify-content: center;
    //         align-items: center;
    //     }
    //   }
    // }

    .ty-Sidebar_menu_parent {
      &.sub_menu_active {
        .ty_icon_padding {
          padding: 14px;
          background: rgba(255, 255, 255, 0.1);
          border-radius: 16px;
        }
      }
    }

    &.active {
      width: 20.9%;
      max-width: 400px;
      // ul li .ty-Sidebar_menu_parent div {
      //   background: none !important;
      //   padding: 0px;
      //   border-radius: 0px;
      // }
      .ty-Sidebar_menu_parent {
        margin-bottom: 4px;

        &:hover {
          background: none;
        }

        .ty-Sidebar_menu_child {
          span {
            letter-spacing: 3.2px;
            text-transform: uppercase;
            @extend %tyFont;
            color: var(--ty-white-color);
          }
        }
        .ty_icon_padding {
          padding: 2px 0px 0px 3px;
          background: transparent;
          border-radius: 0;
        }
      }

      .ty-Sidebar_submenu_menu {
        .MuiListItem-root {
          .Mui-selected.ty-Sidebar_parent_submenu {
            &:hover {
              background: none;
            }

            span {
              &:before {
                width: 24px;
                border-radius: 10px;
              }
            }
          }
        }

        .ty-Sidebar_parent_submenu {
          &:hover {
            background: none;
          }

          .ty-Sidebar_submenu_child {
            margin-top: 4.5px;

            span {
              @extend %tyFont;
              display: flex;
              text-transform: capitalize;
              align-items: center;
              color: var(--ty-white-color);

              &:before {
                background: var(--ty-white-color);
                display: inline-flex;
                width: 6px;
                height: 6px;
                content: "";
                border-radius: 50%;
                margin-right: 14px;
                transition: all 0.3s ease;
                margin-left: 3px;
                margin-top: 1px;
              }

              &:hover {
                &:before {
                  background: var(--ty-white-color);
                  width: 24px;
                  border-radius: 10px;
                }
              }
            }
          }
        }
      }
      .MuiListItemText-root {
        display: block;
      }
    }
    .MuiListItemText-root {
      display: none;
    }

    @media #{$mq-tablet-lg} {
      width: 320px !important;
      background: none;

      .MuiDrawer-paper {
        background: linear-gradient(
          180deg,
          var(--ty-primary-color) 0%,
          var(--ty-primary-hovercolor) 100%
        );
        .rc-scrollbars-view {
          overflow: hidden;
        }
      }
    }

    .custom_header_align {
      margin-bottom: 0;
      margin-top: 0;
      padding: 0;
      min-height: 84px;
      margin-bottom: 28px;

      @media #{$mediaQuery125} {
        min-height: 75px;
        margin-bottom: calc(28px / 2);
      }

      @media #{$mq-tablet-md} {
        min-height: 65px;
      }

      .header_club_logo_area {
        width: 100%;
        padding-top: 16px;

        @media #{$mediaQuery125} {
          padding-top: 5px;
        }

        @media #{$mq-tablet-md} {
          padding-top: 10px;
        }

        padding-right: 0;
        padding-bottom: 11px;
        height: 100%;
        background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="378" height="144" viewBox="0 0 378 144" fill="none"><g filter="url(%23filter0_d_384_131)"><path d="M30 88.9999C30 102.807 41.1168 114 54.9239 114C105.498 114 218.391 114 254 114C300.397 114 303.419 30 344.5 30C385.581 30 30 29.9999 30 29.9999V88.9999Z" fill="white"/></g><defs><filter id="filter0_d_384_131" x="0" y="-0.00012207" width="377.804" height="144" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset/><feGaussianBlur stdDeviation="15"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_384_131"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_384_131" result="shape"/></filter></defs></svg>');
        background-repeat: no-repeat;
        background-position: center center;

        @media #{$mediaQuery125} {
          background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="325" height="102" viewBox="0 0 325 115" fill="none"><g filter="url(%23filter0_d_420_6860)"><path d="M30 70.0009C30 83.808 41.1406 95.0009 54.9478 95.0009C96.1349 95.0009 176.406 95.001 202.559 95.001C238.302 95.001 240.629 30.0001 272.276 30.0001C303.923 30.0001 30 30 30 30V70.0009Z" fill="white"/></g><defs><filter id="filter0_d_420_6860" x="0" y="0" width="304.821" height="125.001" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset/><feGaussianBlur stdDeviation="15"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_420_6860"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_420_6860" result="shape"/></filter></defs></svg>');
          background-repeat: no-repeat;
          background-position: bottom center;
        }

        @media #{$mq-tablet-md} {
          background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="305" height="125" viewBox="0 0 305 125" fill="none"><g filter="url(%23filter0_d_420_6860)"><path d="M30 70.0009C30 83.808 41.1406 95.0009 54.9478 95.0009C96.1349 95.0009 176.406 95.001 202.559 95.001C238.302 95.001 240.629 30.0001 272.276 30.0001C303.923 30.0001 30 30 30 30V70.0009Z" fill="white"/></g><defs><filter id="filter0_d_420_6860" x="0" y="0" width="304.821" height="125.001" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset/><feGaussianBlur stdDeviation="15"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_420_6860"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_420_6860" result="shape"/></filter></defs></svg>');
          background-repeat: no-repeat;
          background-position: center center;
        }

        .club_logo {
          width: 204px;
          height: 52px;
          object-fit: contain;
          object-position: center center;

          @media #{$mediaQuery125} {
            width: 150px;
            height: auto;
          }

          @media #{$mq-laptop} {
            width: 140px;
            height: 50px;
          }
          @media #{$mq-tablet-lg} {
            width: 140px;
            height: 50px;
          }
          @media #{$mq-tablet-md} {
            width: 156px;
            height: 40px;
          }
        }
        .avatar_icon {
          background: #d4e2f2;
          color: #356dad;
          font-weight: 600;
        }
      }
    }
    .rc-scrollbars-track-h {
      visibility: hidden;
    }
  }

  .sidebar_icon {
    padding-left: 30px;
    padding-right: 26px;

    .ty_topbar_notification {
      filter: brightness(100);
      width: 20px;
      height: 20px;
    }
  }

  // .ty_icon_padding {
  //   padding-top: 2px;
  //   padding-left: 3px;
  // }

  .sidebar_footer_logo {
    // padding: 16px 0px 26px 16px;
    margin-left: 16px;
    margin-right: 16px;
    border-style: solid;
    border-color: #5581b3;

    .avatar_icon {
      background: #d4e2f2;
      border-radius: 10px;
      color: #356dad;
      font-weight: 600;
      border: 1px solid #fff;
    }

    .logout_box {
      background-color: #34649a;
      border-radius: 12px;
      padding: 16px;
      .logout_icon {
        background: rgba(255, 255, 255, 0.1);
        color: #fff;
      }
      p {
        color: #fff;
        margin: 16px 0;
        @extend %tyFont;
      }

      .app_logo_footer {
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        padding-top: 16px;
      }
    }
    .logoutFooter {
      .logout_icon {
        background: rgba(255, 255, 255, 0.1);
        color: #fff;
        padding: 14px;
        border-radius: 16px;
      }
    }
  }

  .custom_drawer_button {
    border-radius: 0;
    height: 120px;
    margin-top: 55px;
    padding: 0;

    &::before {
      content: "";
      width: 0.1px;
      background: #326aaa;
      height: 85px;
    }

    /*margin-left: -0.6px;*/
    .arrow_menu {
      position: absolute;
      color: #fff;
      padding: 0 !important;
      font-size: 28px;
      margin-right: 5px;
    }

    &:hover {
      background: none;
    }
  }

  .sidebarmenu_adjust {
    padding: 0;

    li {
      &:nth-last-child(01) hr {
        visibility: hidden;
        margin: 0px;
      }

      hr {
        margin-right: 26px;
        margin-left: 30px;
        border-color: #5581b3;
      }
    }
  }

  @media #{$mq-tablet-lg} {
    .custom_header_css {
      z-index: 1;
    }

    .custom-drawer-class {
      position: absolute;
      height: 100%;
    }
  }
}
