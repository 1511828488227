@import "variables";
.ty_custom_topbar {
    padding: 8px 0px;
    box-shadow: none !important;
    background-color: transparent !important;
    .top_searc_bar {
        input {
            font-family: var(--ty-font-family);
            font-weight: 500;
        }
    }
    .mob_search_bar{
      transition: all .5s ease-in-out;
      max-height: 0;
      overflow: hidden;
      padding-left: 26px;
      padding-right: 26px;
      padding-bottom: 0px;
    }

    .mob_search_bar.open {
      transition: all .5s ease-in-out;
      padding-bottom: 10px;
      max-height: 100px;
 }
    .ty_topbar_notification {
        height: 40px;
        width: 40px;
        @media #{$mediaQuery125} {
          height: 35px;
          width: 35px;
        }
        border: 1px solid transparent;
        background-color: #f5f9fa;
        border-radius: 8px;
        img {
          @media #{$mediaQuery125} {
            width: 18px;
          }
        }
    }
}




div#icon3 span {
    display: block;
    position: absolute;
    height: 6.5px;
    width: 6.5px;
    border: 1px solid #356DAD;
    opacity: 1;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}
div#icon3 {
    width: 24px;
    height: 24px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}

#icon3 span:nth-child(even) {
  left: 50%;
  border-radius: 30%;
}

#icon3 span:nth-child(odd) {
  left:0px;
  border-radius: 30%;
}
#icon3 span:nth-child(1), #icon3 span:nth-child(2), #icon3 span:nth-child(3) {
  top: 0px;
}
#icon3 span:nth-child(4), #icon3 span:nth-child(5), #icon3 span:nth-child(6) {
  top: 10px;
}
#icon3 span:nth-child(7), #icon3 span:nth-child(8), #icon3 span:nth-child(9) {
  top: 20px;
}
#icon3 span:nth-child(3) {
    left: 100%;
}

#icon3 span:nth-child(6) {
    left: 100%;
}

#icon3 span:nth-child(9) {
    left: 100%;
}

#icon3.open span:nth-child(2) {
  top: 10px;
}

#icon3.open span:nth-child(5) {
  left: 50%;
}
#icon3.open span:nth-child(6) {
  left: 50%;
}

#icon3.open span:nth-child(8) {
  top: 10px;
}