@import "variables";

.Race_session_items {
  border: 1px solid var(--ty-primary-color);
  cursor: pointer;
  border-radius: 12px;
  padding: 11.5px 12px;
  height: 132px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  &:hover {
    background: rgba(53, 109, 173, 0.1);
  }
  .Race_item_imgBox {
    border-radius: 50%;
    @extend %ty_circle;
    background: rgba(53, 109, 173, 0.1);
    width: 48px;
    height: 48px;
  }

  .Race_session_font {
    // @extend %tyFont;
    font-size: var(--font_size14) !important;
    color: var(--ty-primary-color) !important;
    font-weight: 600;
    margin-top: 10px;
  }
}

.Race_session_setup_box {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 8px 0 64px;
  span {
    color: var(--ty-teritory-color) !important;
  }
}

#select_source {
  .MuiPopover-paper {
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    .cst_accordion {
      background: rgba(244, 245, 246, 1);
      border-radius: 0;
      border: 0;
      box-shadow: none;
      .information_icon {
        font-size: 16px;
        margin-top: 2px;
      }
      .important_notes_heading {
        margin-left: 8px;
      }
      .important_notes_heading,
      svg {
        color: var(--ty-primary-color);
        font-weight: 500;
      }
      li {
        padding: 10px 0;
      }
      p,
      ul li {
        font-size: 14px;
        color: rgba(66, 86, 108, 1);
      }
      .bullet_point {
        font-size: 7px;
      }
      ul {
        list-style: disc;
        list-style-type: disc;
      }

      .Mui-expanded {
        margin: 12px 0;
        min-height: auto;
      }
    }
    .select_source_div {
      .file_upload_div_section {
        .upload_btn {
          .MuiButton-sizeMedium {
            padding: 16px 24px;
            border-radius: 8px;
            border: 1px dashed rgba(53, 109, 173, 1);
            background: rgba(243, 247, 251, 1);
            display: flex;
            justify-content: center;

            svg {
              color: var(--ty-primary-color);
              font-size: 26px;
              margin-bottom: 12px;
            }
          }

          div {
            display: flex;
            justify-items: center;
            flex-direction: column;
            align-items: center;
            font-weight: 400;
            text-align: center;
            text-transform: capitalize;

            .cst_link_click_select {
              color: var(--ty-primary-color);
              &:after {
                @extend %line_cst;
              }
            }
          }
        }
      }

      .file_uploading {
        display: flex;
        justify-content: space-between;
        border: 1px solid #dedede;
        background: #fafafa;
        padding: 10px 16px;
        border-radius: 12px;
        font-size: 14px;
        font-weight: 500;
        align-items: center;
        color: var(--ty-secondary-color);

        .csv_file {
          border-radius: 8px;
          width: 36px;
          height: 36px;
          @extend %ty_circle;
        }
      }

      .button_group_div {
        margin: 10px 16px 12px 16px;
      }
    }
  }
}

// For Data Import Modal Label Css
.dataImportModalLabels label span {
  font-weight: 400 !important;
  font-size: 16px !important;
}
// For Data Import Modal Label Css

.optionsScroll {
  max-height: 202px;
  overflow-y: scroll;
  padding-bottom: 10px;
  .MuiInputBase-root {
    width: 48px !important;
    margin: 0 auto;
    border-radius: 8px !important;
    .MuiInputBase-input {
      padding: 4.5px 10px;
    }
  }

  .MuiTypography-p {
    @extend %tyFont;
    font-weight: 500;
  }
}

// For Duplicate Row
.duplicateDataRow {
  background: #f5a13b !important;
  .MuiTypography-p {
    color: #fff !important;
  }
}
// For Duplicate Row

// FinishTimeEntryPage RealtimeCapture Span
.MuiButtonBase-root.preloadSpan {
  border: 2px solid #35ad57;
  background: #fff;
  padding: 8px 14px;
  // font-size: 17px;
  @extend %ty_p_Font;
  border-radius: 36px;
  color: #35ad57;
  font-weight: 600;
  width: 115px;
  height: 40px;
}
// FinishTimeEntryPage RealtimeCapture Span

// FinishTimeEntryPage FinishedTable
.SailNoField {
  .MuiInputBase-root {
    background: #8af4b5 !important;
    font-weight: 600;
    fieldset {
      border-color: #8af4b5 !important;
    }
    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid #8af4b5 !important;
      }
    }
  }
  .MuiInputBase-root.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid #8af4b5 !important;
      box-shadow: 0 0px 2px #8af4b5 !important;
    }
  }
}
// FinishTimeEntryPage FinishedTable

// For Ams Fleet Datagrid
.amsFleetTab .MuiBox-root:nth-child(1),
.handicapTab .MuiBox-root:nth-child(1) {
  padding: 0;
}
// For Ams Fleet Datagrid

// For Series and Fleets In Results List
.seriesFleetsList {
  li.MuiListItem-root {
    padding: 6px 0px;
    text-align: center;
    .MuiListItemText-root {
      .MuiTypography-root {
        @extend %tyFont;
        color: var(--ty-secondary-color);
        font-weight: 500;
      }
    }
    .MuiListItemText-root.textGreen {
      a {
        color: #05a929;
        text-decoration: none;
      }
    }
    .MuiListItemText-root.textBlue {
      a {
        color: var(--ty-primary-color);
        text-decoration: none;
      }
    }
    .MuiListItemText-root.primaryUnderline {
      a {
        text-decoration: underline;
        font-weight: 500;
        color: var(--ty-primary-color);
        text-transform: capitalize;
      }
    }
  }
}
// For Series and Fleets In Results List

// FOR RaceOverviewModal
.RaceOverviewModal {
  .MuiTypography-a {
    @extend %tyFont;
    color: var(--ty-primary-color);
    text-decoration: underline;
    font-weight: 500;
  }
  .txt_with_dot {
    width: 100px;
  }
  .txt_with_dot.missing_data {
    .MuiTypography-p {
      color: var(--missing_data);
    }
  }
  .txt_with_dot.new_data {
    .MuiTypography-p {
      color: var(--new_data);
    }
  }
  .txt_with_dot.published {
    .MuiTypography-p {
      color: var(--published);
    }
  }
  .backBtnCustom {
    color: var(--ty-primary-color) !important;
    text-decoration: underline;
  }
  .modalHeading {
    @extend %ty_innerheading_Font;
    color: var(--ty-secondary-color);
    span.missing_data_color {
      font-weight: 600;
      color: var(--missing_data);
    }
    span.published_color {
      font-weight: 600;
      color: var(--published);
    }
    span.new_data_color {
      font-weight: 600;
      color: var(--new_data);
    }
  }
}
// FOR RaceOverviewModal

.cst_add_class {
  .MuiDataGrid-cell:last-child {
    svg {
      display: none;
    }
  }
  .MuiDataGrid-row.CustomAddedRow {
    svg {
      display: block;
    }
  }
}
// FOR SCORE DEFINITION FIELD BORDER COLOR
.stepField.MuiDataGrid-cell--editing .MuiInputBase-root,
.limitField.MuiDataGrid-cell--editing .MuiInputBase-root {
  border: 1px solid #c2cbd6;
  &:hover {
    border-color: #9dacbd;
  }
}
// FOR SCORE DEFINITION FIELD BORDER COLOR

// For login button disabled color
.loginButton {
  &.ty_primary_btn.MuiButtonBase-root.Mui-disabled {
    color: #ffffffcc;
    background: var(--ty-primary-color);
  }
}
// For login button disabled color
