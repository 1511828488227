@import "variables";

.ty_datagrid_container {
  background: #f1f7f9;
  padding: 16px;
  border-radius: 16px;
  box-shadow: none;
  border: none;
  --DataGrid-rowBorderColor: none;
  .MuiTablePagination-root {
    .MuiToolbar-root {
      .MuiTablePagination-input {
        width: auto;
      }
    }
  }

  .MuiInputBase-input {
    padding: 8px 12px;
  }

  .MuiDataGrid-columnHeader {
    &:focus,
    &:focus-within {
      outline: none;
    }

    .MuiFormControl-root {
      .MuiFormLabel-root {
        z-index: 9;
        transform: translate(10px, 23px);
        color: #c2cbd6;
        font-weight: 400;
        font-size: 14px;
        &.Mui-focused {
          transform: translate(10px, 23px);
          opacity: 0;
        }
        &.MuiFormLabel-filled {
          opacity: 0;
        }
      }
      .MuiInputBase-root {
        background: #fff;
        overflow: hidden;
        border: 1px solid #c2cbd6;
        &:hover {
          border: 1px solid #9dacbd;
        }
        &:before,
        &:after {
          border: none;
        }
        &.Mui-focused {
          border: 1px solid var(--ty-primary-color);
          box-shadow: 0 0px 2px rgba(53, 109, 173, 1);
        }
      }
    }

    // .MuiFormControl-root {
    //   .MuiFormLabel-root {
    //     display: none;
    //     // z-index: 9;
    //   }

    //   .MuiInputBase-root {
    //     // padding: 0 13px;
    //     height: 32px;
    //     margin: 0;
    //     border: 1px solid #c2cbd6;
    //     border-radius: 8px;
    //     &:hover {
    //       border: 1px solid #9dacbd;
    //     }

    //     &:before,
    //     &:after {
    //       border: none;
    //     }
    //   }
    //   .MuiInputBase-root.Mui-focused {
    //     border: 1px solid var(--ty-primary-color);
    //     box-shadow: 0 0px 2px rgba(53, 109, 173, 1);
    //   }
    // }
    .MuiDataGrid-columnHeaderTitleContainer {
      justify-content: space-between;
      flex-direction: row;
    }
    .MuiDataGrid-columnHeaderTitle {
      text-transform: uppercase;
      display: block;
      width: 100%;
      font-weight: 600;
      color: var(--ty-secondary-color);
    }
    &:focus {
      outline: none;
    }
  }
  .MuiDataGrid-virtualScrollerRenderZone {
    gap: 12px;
    .MuiDataGrid-row {
      background: #fff;
      border-radius: 20px;
      // max-height: inherit !important;
      border-top-left-radius: 48px;
      border-bottom-left-radius: 48px;
      border-top-right-radius: 48px;
      border-bottom-right-radius: 48px;
      // overflow: hidden;
      display: inline-flex;
      align-items: center;
      box-shadow: none;
      max-height: 48px !important;
      height: 48px !important;
      &:nth-child(2n) {
        background: #d8e9ee;
      }
      // &:last-child {
      //   margin-bottom: 28px;
      // }
      &:hover {
        // background: #d8e9ee;
        background: #fdf6e2;
        .MuiDataGrid-cell--pinnedLeft,
        .MuiDataGrid-cell--pinnedRight {
          background-color: inherit;
        }
      }
      .MuiDataGrid-columnSeparator {
        border-color: red;
        color: red !important;
      }
      .MuiDataGrid-cell--pinnedLeft {
        background-color: inherit;
        border-top-left-radius: 48px;
        border-bottom-left-radius: 48px;
        border-right: 1px solid #c3ced152;
      }
      .MuiDataGrid-cell--pinnedRight {
        background-color: inherit;
        border-left: 1px solid #c3ced152;
        border-top-right-radius: 48px;
        border-bottom-right-radius: 48px;
      }
      .MuiDataGrid-rowReorderCell.MuiDataGrid-rowReorderCell--draggable svg {
        color: #c6d9ec;
      }
    }
  }
  .MuiDataGrid-container--top [role="row"] {
    background-color: #f1f7f9;
    .MuiDataGrid-columnHeader--pinnedLeft {
      background-color: inherit;
      border-right-color: #c3ced152;
    }
    .MuiDataGrid-columnHeader--pinnedRight {
      background-color: inherit;
      border-left-color: #c3ced152;
    }
  }
  .MuiDataGrid-cell {
    border: 0;
    min-height: 48px;
    height: 48px;
    display: inline-flex;
    align-items: center;
    // justify-content: center;
    &:focus,
    &:focus-within {
      outline: none !important;
    }
    &:nth-child(01) {
      padding-left: 24px;
    }
    &:last-child {
      padding-right: 24px;
    }
  }
  .MuiDataGrid-cell.justifyCenter {
    justify-content: center;
  }
  .MuiDataGrid-filler--pinnedLeft,
  .MuiDataGrid-filler--pinnedRight {
    display: none;
  }
  .MuiTablePagination-input {
    display: inline-flex !important;
  }
}

// NEW CSS FOR GRID EDITABLE

.ty_inlineEdit_datagrid {
  background: #f1f7f9;
  padding: 12px 16px 16px 16px;
  border-radius: 16px;
  box-shadow: none;
  border: none;
  --DataGrid-rowBorderColor: none;

  .MuiDataGrid-main {
    padding-bottom: 12px;
  }

  .ellipsisGridText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .MuiTablePagination-root {
    .MuiToolbar-root {
      .MuiTablePagination-input {
        width: auto;
      }
    }
  }

  .MuiDataGrid-cell--editing.MuiDataGrid-cell {
    background: none;
    box-shadow: none;
    .MuiInputBase-root {
      border: 1px solid #c2cbd6;
    }
  }

  .MuiInputBase-input {
    padding: 4px 12px;
    @extend %tyFont;
    color: var(--ty-secondary-color);
  }

  .MuiDataGrid-columnHeader {
    &:focus,
    &:focus-within {
      outline: none;
    }
    .MuiFormControl-root {
      .MuiFormLabel-root {
        z-index: 1;
        transform: translate(10px, 23px);
        color: #c2cbd6;
        font-weight: 400;
        font-size: 14px;
        &.Mui-focused {
          transform: translate(10px, 23px);
          opacity: 0;
          z-index: -1;
        }
        &.MuiFormLabel-filled {
          opacity: 0;
        }
      }
      .MuiInputBase-root {
        background: #fff;
        overflow: hidden;
        border: 1px solid #c2cbd6;
        margin-bottom: 20px;
        &:hover {
          border: 1px solid #9dacbd;
        }
        &:before,
        &:after {
          border: none;
        }
        &.Mui-focused {
          border: 1px solid var(--ty-primary-color);
          box-shadow: 0 0px 2px rgba(53, 109, 173, 1);
        }
      }
    }

    .MuiDataGrid-columnHeaderTitleContainer {
      justify-content: space-between;
      flex-direction: row;
    }
    .MuiDataGrid-columnHeaderTitle {
      text-transform: uppercase;
      display: block;
      width: 100%;
      font-weight: 600;
      color: var(--ty-secondary-color);
    }
    &:focus {
      outline: none;
    }
    .MuiAutocomplete-root {
      width: 100%;
      .MuiFormLabel-root {
        display: none;
      }
      .MuiInputBase-root {
        min-height: auto;
        max-height: 48px;
        overflow-y: auto;
        padding-left: 8px !important;
      }
    }
  }
  .MuiDataGrid-virtualScrollerRenderZone {
    .MuiDataGrid-row {
      background: #fff;
      border-radius: 20px;
      border-top-left-radius: 48px;
      border-bottom-left-radius: 48px;
      border-top-right-radius: 48px;
      border-bottom-right-radius: 48px;
      display: inline-flex;
      align-items: center;
      box-shadow: none;
      // max-height: 48px !important;
      // height: 48px !important;
      &:nth-child(2n) {
        background: #d8e9ee;
      }

      &:hover {
        // background: #d8e9ee;
        background: #fdf6e2;
        .MuiDataGrid-cell--pinnedLeft,
        .MuiDataGrid-cell--pinnedRight {
          background-color: inherit !important;
        }
      }
      .MuiDataGrid-columnSeparator {
        border-color: red;
        color: red !important;
      }
      .MuiDataGrid-cell--pinnedLeft {
        background-color: inherit;
        border-top-left-radius: 48px;
        border-bottom-left-radius: 48px;
        border-right: 1px solid #c3ced152;
      }
      .MuiDataGrid-cell--pinnedRight {
        background-color: inherit;
        border-left: 1px solid #c3ced152;
        border-top-right-radius: 48px;
        border-bottom-right-radius: 48px;
      }
      .MuiDataGrid-rowReorderCell.MuiDataGrid-rowReorderCell--draggable svg {
        color: #c6d9ec;
      }
    }
    .MuiDataGrid-row--editable {
      .MuiInputBase-root {
        border: 1px solid #c2cbd6;
        &:hover {
          border: 1px solid #9dacbd;
        }
      }
      .MuiInputBase-root.Mui-focused {
        border: 1px solid var(--ty-primary-color);
        box-shadow: 0 0px 2px rgba(53, 109, 173, 1);
      }
    }
  }
  .MuiDataGrid-container--top [role="row"] {
    background-color: #f1f7f9;
    .MuiDataGrid-columnHeader--pinnedLeft {
      background-color: #f1f7f9;
      border-right-color: #c3ced152;
    }
    .MuiDataGrid-columnHeader--pinnedRight {
      background-color: #f1f7f9;
      border-left-color: #c3ced152;
    }
  }
  .MuiDataGrid-cell {
    border: 0;
    // min-height: 48px;
    // height: 48px;
    display: inline-flex;
    align-items: center;
    // background: transparent !important;
    @extend %tyFont;
    color: var(--ty-secondary-color);
    // justify-content: center;
    &:focus,
    &:focus-within {
      outline: none !important;
    }
    &:nth-child(01) {
      padding-left: 24px;
    }
    &:last-child {
      padding-right: 24px;
    }
  }
  .MuiDataGrid-filler--pinnedLeft,
  .MuiDataGrid-filler--pinnedRight {
    display: none;
  }
}

.ty_inlineEdit_datagrid.entrants {
  .Mui-disabled {
    // -webkit-text-fill-color: var(--ty-secondary-color) !important;
    // @extend %ty_p_Font;
    // color: var(--ty-secondary-color);
  }
  .MuiCheckbox-root.Mui-disabled {
    color: #777;
  }
  .MuiCheckbox-root.Mui-checked {
    color: var(--ty-primary-color);
  }
  .MuiInputBase-root.Mui-disabled {
    background: none;
    font-size: 18px;
    font-weight: 500;
    input {
      padding: 0;
    }
    fieldset {
      border: none;
    }
  }
  .MuiInputBase-root {
    fieldset {
      border: none;
    }
    &.Mui-focused {
      fieldset {
        box-shadow: none;
      }
    }
  }
}
// DATAGRID WITHOUT EDIT

.ty_datagrid {
  background: #f1f7f9;
  padding: 12px 16px 16px 16px;
  border-radius: 16px;
  box-shadow: none;
  border: none;
  --DataGrid-rowBorderColor: none;

  .MuiDataGrid-main {
    padding-bottom: 12px;
  }

  .ellipsisGridText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .MuiTablePagination-root {
    .MuiToolbar-root {
      .MuiTablePagination-input {
        width: auto;
      }
    }
  }

  .MuiInputBase-input {
    padding: 4px 12px;
    @extend %ty_p_Font;
    color: var(--ty-secondary-color);
  }

  .MuiDataGrid-columnHeader {
    &:focus,
    &:focus-within {
      outline: none;
    }
    .MuiFormControl-root {
      .MuiFormLabel-root {
        z-index: 9;
        transform: translate(10px, 23px);
        color: #c2cbd6;
        font-weight: 400;
        font-size: 14px;
        &.Mui-focused {
          transform: translate(10px, 23px);
          opacity: 0;
          z-index: -1;
        }
        &.MuiFormLabel-filled {
          opacity: 0;
        }
      }
      .MuiInputBase-root {
        background: #fff;
        overflow: hidden;
        border: 1px solid #c2cbd6;
        margin-bottom: 12px;
        &:hover {
          border: 1px solid #9dacbd;
        }
        &:before,
        &:after {
          border: none;
        }
        &.Mui-focused {
          border: 1px solid var(--ty-primary-color);
          box-shadow: 0 0px 2px rgba(53, 109, 173, 1);
        }
      }
    }

    // .MuiFormControl-root {
    //   .MuiFormLabel-root {
    //     display: none;
    //     // z-index: 9;
    //   }

    //   .MuiInputBase-root {
    //     // padding: 0 13px;
    //     height: 32px;
    //     margin: 0;
    //     border: 1px solid #c2cbd6;
    //     border-radius: 8px;
    //     &:hover {
    //       border: 1px solid #9dacbd;
    //     }

    //     &:before,
    //     &:after {
    //       border: none;
    //     }
    //   }
    //   .MuiInputBase-root.Mui-focused {
    //     border: 1px solid var(--ty-primary-color);
    //     box-shadow: 0 0px 2px rgba(53, 109, 173, 1);
    //   }
    // }
    .MuiDataGrid-columnHeaderTitleContainer {
      justify-content: space-between;
      flex-direction: row;
    }
    .MuiDataGrid-columnHeaderTitle {
      text-transform: uppercase;
      display: block;
      width: 100%;
      font-weight: 600;
      color: var(--ty-secondary-color);
    }
    &:focus {
      outline: none;
    }
  }
  .MuiDataGrid-virtualScrollerRenderZone {
    .MuiDataGrid-row {
      background: #fff;
      border-radius: 20px;
      border-top-left-radius: 48px;
      border-bottom-left-radius: 48px;
      border-top-right-radius: 48px;
      border-bottom-right-radius: 48px;
      display: inline-flex;
      align-items: center;
      box-shadow: none;
      // max-height: 48px !important;
      // height: 48px !important;
      &:nth-child(2n) {
        background: #d8e9ee;
      }

      &:hover {
        // background: #d8e9ee;
        background: #fdf6e2;
        .MuiDataGrid-cell--pinnedLeft,
        .MuiDataGrid-cell--pinnedRight {
          background-color: inherit !important;
        }
      }
      .MuiDataGrid-columnSeparator {
        border-color: red;
        color: red !important;
      }
      .MuiDataGrid-cell--pinnedLeft {
        background-color: inherit;
        border-top-left-radius: 48px;
        border-bottom-left-radius: 48px;
        border-right: 1px solid #c3ced152;
      }
      .MuiDataGrid-cell--pinnedRight {
        background-color: inherit;
        border-left: 1px solid #c3ced152;
        border-top-right-radius: 48px;
        border-bottom-right-radius: 48px;
      }
      .MuiDataGrid-rowReorderCell.MuiDataGrid-rowReorderCell--draggable svg {
        color: #c6d9ec;
      }
      &.Mui-selected {
        background-color: var(--ty-primary-color);
        .MuiDataGrid-cell {
          color: var(--ty-white-color);
        }
      }
    }
    p.Mui-error {
      display: none;
    }
    // .MuiDataGrid-row--editable {
    //   // .MuiInputBase-root {
    //   //   // border: 1px solid #c2cbd6;
    //   //   // &:hover {
    //   //   //   border: 1px solid #9dacbd;
    //   //   // }
    //   // }
    //   // .MuiInputBase-root.Mui-focused {
    //   //   border: 1px solid var(--ty-primary-color);
    //   //   box-shadow: 0 0px 2px rgba(53, 109, 173, 1);
    //   // }
    // }
  }
  .MuiDataGrid-container--top [role="row"] {
    background-color: #f1f7f9;
    .MuiDataGrid-columnHeader--pinnedLeft {
      background-color: #f1f7f9;
      border-right-color: #c3ced152;
    }
    .MuiDataGrid-columnHeader--pinnedRight {
      background-color: #f1f7f9;
      border-left-color: #c3ced152;
    }
  }
  .MuiDataGrid-cell {
    border: 0;
    // min-height: 48px;
    // height: 48px;
    display: inline-flex;
    align-items: center;
    background: transparent !important;
    @extend %tyFont;
    color: var(--ty-secondary-color);
    // justify-content: center;
    &:focus,
    &:focus-within {
      outline: none !important;
    }
    &:nth-child(01) {
      padding-left: 24px;
    }
    &:last-child {
      padding-right: 24px;
    }
  }
  .MuiDataGrid-filler--pinnedLeft,
  .MuiDataGrid-filler--pinnedRight {
    display: none;
  }
  .MuiTablePagination-input {
    display: inline-flex !important;
  }
  .MuiDataGrid-footerContainer {
    margin-top: 10px;
  }
}

// DATAGRID WITHOUT EDIT

.MuiTablePagination-input
  .MuiSelect-select.MuiTablePagination-select.MuiInputBase-input {
  font-size: 14px;
}
.amsTab .MuiDataGrid-editInputCell.MuiInputBase-root {
  border: 1px solid #c2cbd6;
  &.Mui-focused {
    border: 1px solid var(--ty-primary-color);
    box-shadow: 0 0px 2px rgba(53, 109, 173, 1);
  }
}
