@import "variables.scss";

//Select Drop down
.MuiMenu-list {
  max-height: 210px;
  overflow-y: auto;
  margin: 8px 5px 8px 8px !important;
  padding: 0px !important;
  li,
  a {
    padding: 12.5px 12px;
    border-radius: 8px;
    font-size: 14px;
    color: var(--ty-secondary-color);
    height: 42px;
    margin-right: 3px;
    font-weight: 400;
    text-transform: capitalize;
    &:hover {
      background-color: #e8eff8;
    }
  }

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 51px;
  }
}
.MuiAutocomplete-popper {
  box-shadow: none;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  overflow: auto;
  li {
    // padding: 12.5px 12px;
    border-radius: 8px;
    font-size: 14px;
    // height: 42px;
    color: var(--ty-secondary-color);
    margin-right: 3px;
    font-weight: 400;
    padding: 3px 16px !important;
    &:hover {
      background-color: #e8eff8;
    }
  }

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 51px;
  }
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.MuiMenu-paper.MuiMenu-paper.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  box-shadow: none;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
}

//
.tyListItem {
  li {
    margin-bottom: 10px;
    .list_numbering {
      @extend %ty_circle;
      background: var(--ty-primary-color);
      padding: 0px;
      width: 24px;
      height: 24px;
      margin-right: 12px;
    }
    .listCustomStyling {
      span {
        font-weight: 500;
        line-height: 26px;
        @extend %ty_p_Font;
        color: var(--ty-secondary-color);
      }
    }
  }
}

// RC SCROLL
.rc-scrollbars-thumb {
  background: #c6dee7 !important;
  border-radius: 60px !important;
}

.rc-scrollbars-track {
  background: transparent !important;
}

.ty_application_design .ty_table_container_exp .rc-scrollbars-track-h {
  height: 12px !important;
}
.ty_application_design .ty_table_container_exp .rc-scrollbars-track-v {
  width: 12px !important;
}

.ty_application_design .ty_table_container_exp::-webkit-scrollbar-thumb {
  background: #c6dee7;
  border-radius: 60px;
}

.ty_application_design .ty_table_container_exp::-webkit-scrollbar-track {
  background: transparent;
}
.ty_application_design .ty_table_container_exp::-webkit-scrollbar {
  width: 12px;
}

// For Score Point Def Table
.ty_application_design
  .ty_table_container_exp.scoreDefinitionTable
  .rc-scrollbars-track-h {
  display: none !important;
}
// For Score Point Def Table
// RC SCROLL

// FIREFOX SCROLLBAR
body {
  scrollbar-color: #c6dee7 transparent;
  scrollbar-width: auto;
}
// FIREFOX SCROLLBAR

// MARGIN CLASSES
.marginTop {
  margin-top: 0px;
}
.marginBottom {
  margin-bottom: 0px;
}
// MARGIN CLASSES

// FOR DATE PICKER YEARS
.MuiDateCalendar-root .MuiYearCalendar-root {
  max-height: 330px;
}
// FOR DATE PICKER YEARS

// For ToolTip
.MuiTooltip-popper {
  .MuiTooltip-tooltip {
    font-size: 13px;
    line-height: 15.6px;
    font-weight: 400;
    color: #fff;
    background-color: rgba(66, 86, 108, 0.95);
    padding: 12px;
    max-width: 320px;
    min-width: fit-content;
    border-radius: 8px;
    @media #{$mq-mobile-sm} {
      max-width: 200px;
    }
  }
  .MuiTooltip-arrow {
    &::before {
      background: rgba(66, 86, 108, 0.95);
    }
  }
}

// For ToolTip

.txt_with_dot {
  padding: 5px 16px;
  text-align: center;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  &:before {
    width: 8px;
    height: 8px;
    content: "";
    border-radius: 50%;
  }
  &.published {
    background: rgba(224, 255, 231, 1);
    &:before {
      background: var(--published);
    }
    span {
      color: var(--published);
      &:after {
        @extend %line_cst;
        background-color: var(--published);
      }
    }
  }
  &.missing_data {
    background: rgba(255, 214, 213, 0.5);
    span {
      color: var(--missing_data);
      &:after {
        @extend %line_cst;
        background-color: var(--missing_data);
      }
    }
    &:before {
      background: var(--missing_data);
    }
  }
  &.new_data {
    background: rgba(255, 233, 208, 0.5);
    span {
      color: var(--new_data);
      &:after {
        @extend %line_cst;
        background-color: var(--new_data);
      }
    }
    &:before {
      background: var(--new_data);
    }
  }
}

// For Colored Checkbox CSS
.checkboxColored {
  text-align: center;
  border-radius: 12px;
  .MuiFormControlLabel-root {
    margin: 0;
  }
}
.checkboxColored.published {
  background: rgba(224, 255, 231, 1);
  .Mui-checked {
    color: var(--published);
  }
}
.checkboxColored.missing_data {
  background: rgba(255, 214, 213, 0.5);
  .Mui-checked {
    color: var(--missing_data);
  }
}
.checkboxColored.new_data {
  background: rgba(255, 233, 208, 0.5);
  .Mui-checked {
    color: var(--new_data);
  }
}

// For Colored Checkbox CSS

//Calendar UI

//Drop Shadow
.css-3bmhjh-MuiPaper-root-MuiPopover-paper,
.css-1k4oq0i-MuiPaper-root-MuiPickersPopper-paper {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.1),
    0px 8px 10px 1px rgba(0, 0, 0, 0.02), 0px 3px 14px 2px rgba(0, 0, 0, 0.05) !important;
}

// Custom HR
.cst_hr {
  margin: 16px 0;
  background: #dbdbdb;
  height: 1px;
  display: block;
}

//
.wednesday_wonders {
  background: var(--wednesday_wonders);
}
.oD_short_series,
.published_data {
  background: var(--OD_short_series);
}
.saturday_racing {
  background: var(--saturday_racing);
}
.cruiser_series {
  background: var(--cruiser_series);
}
.missing_data {
  background: var(--missing_data);
}
.new_data {
  background: var(--new_data);
}

.cst_lable_popup_screen_key {
  display: flex;
  align-items: center;
  gap: 8px;
  h4 {
    margin: 0 !important;
    padding: 0;
  }
}
.cst_lable_popup_screen {
  padding: 10px 0;
  h4 {
    @extend %ty_heading;
    margin-bottom: 8px;
  }
  .label_cst_calendar_ui {
    margin: 0;
    padding: 0;
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    @media #{$mq-mobile-sm} {
      flex-direction: row;
    }
    li {
      padding: 0;
      list-style: none;
      align-items: center;
      .cst_label {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
        @extend %tyFont;
        color: var(--ty-secondary-color);
        text-transform: capitalize;
        .dot {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          display: flex;
        }
      }
    }
  }
}

.MuiDataGrid-menu {
  .MuiPaper-root {
    box-shadow: 0px 4px 20px 0px #00000014;

    .MuiButtonBase-root {
      @media #{$mq-mobile-sm} {
        min-height: 40px;
      }
      .MuiListItemIcon-root {
        svg {
          color: var(--ty-primary-color);
        }
      }
      .MuiListItemText-root {
        .MuiTypography-root {
          font-size: var(--font_size14);
          color: var(--ty-secondary-color);
        }
      }
      &:hover {
        background: var(--ty-primary-color);
        .MuiListItemIcon-root {
          svg {
            color: #fff;
          }
        }
        .MuiListItemText-root {
          .MuiTypography-root {
            color: #fff;
          }
        }
      }
    }
  }
}
.MuiDataGrid-panel {
  .MuiDataGrid-columnsManagement {
    max-height: 200px;
  }
  .MuiTypography-root {
    font-size: var(--font_size14);
    color: var(--ty-secondary-color);
  }
  .MuiInputBase-root {
    border-radius: 12px;
    fieldset {
      border-color: #c2cbd6;
      border-width: 1px;
    }
    &:hover {
      fieldset {
        border-color: #9dacbd;
      }
    }
  }
  .MuiInputBase-root.Mui-focused {
    fieldset {
      border-color: var(--ty-primary-color);
      border-width: 1px;
    }
  }
}

// Calendar CSS

.MuiPickersPopper-paper {
  border-radius: 8px !important;
  .MuiPickersCalendarHeader-label {
    color: var(--ty-secondary-color);
    font-weight: 600;
  }
  .MuiDayCalendar-header {
    .MuiTypography-root {
      font-size: 16px;
      font-weight: 500;
    }
  }
  .MuiDayCalendar-monthContainer {
    .MuiPickersDay-root {
      font-size: 16px;
      color: var(--ty-secondary-color);
      font-weight: 500;
    }
    .MuiPickersDay-root.Mui-selected {
      color: #fff;
    }

    .MuiPickersDay-today {
      background: var(--ty-primary-color) !important;
      border: 1px solid var(--ty-primary-color) !important;
      color: #fff;
      &:hover {
        color: var(--ty-primary-color) !important;
        background: #fff !important;
        border: 1px solid var(--ty-primary-color) !important;
      }
    }
  }
}

// Calendar CSS

// FOR ELIPSIS AUTOCOMPLETE
span.elipsisAutocompleteOptions {
  width: auto;
  text-overflow: ellipsis;
  overflow: hidden;
}
// FOR ELIPSIS AUTOCOMPLETE

// FOR CLUBVIEW PAGE FLAG
.disableClickFunction .MuiInputAdornment-root {
  pointer-events: none;
}
// FOR CLUBVIEW PAGE FLAG

// FOR DATAGRID Score Point Autocomplete
.MuiAutocomplete-root {
  .MuiInputBase-root {
    padding: 4px 12px !important;
  }
}

// FOR DATAGRID Score Point Autocomplete
